import React from 'react';
import { Helmet } from 'react-helmet-async';
import { graphql, PageRendererProps } from 'gatsby';
import { FluidObject } from 'gatsby-image';

import { getMimeTypeFromExtension } from '../utils';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Content, { HTMLContent } from '../components/Content';

interface HeroImage extends FluidObject {
  width: number;
  height: number;
}

interface TemplateProps {
  title: string;
  image: string | HeroImage;
  focalPoint: {
    x: number;
    y: number;
  };
  heading: string;
  subheading: string;
  content: string;
  contentComponent?: typeof Content | typeof HTMLContent;
  location?: string;
  schema?: object;
}

interface PageProps extends PageRendererProps {
  data: {
    markdownRemark: {
      html: string;
      frontmatter: {
        type: string;
        title: string;
        description: string;
        heroImage: {
          alt?: string;
          focalPoint: {
            x: number;
            y: number;
          };
          image: {
            childImageSharp: {
              hero: HeroImage;
              openGraph: { src: string; width: number; height: number };
              twitter: { src: string; width: number; height: number };
            };
          };
        };
        heading: string;
        subheading: string;
      };
    };
  };
}

export function HeroPageTemplate({
  title,
  location,
  schema,
  image,
  focalPoint,
  heading,
  subheading,
  content,
  contentComponent,
}: TemplateProps) {
  const PageContent = contentComponent || Content;

  return (
    <Layout location={location} title={title} schema={schema}>
      <Hero
        image={image}
        heading={heading}
        subheading={subheading}
        focalPoint={focalPoint}
      />
      <section className="section">
        <div className="container">
          <PageContent className="content" content={content} />
        </div>
      </section>
    </Layout>
  );
}

export default function HeroPage({ data, location }: PageProps) {
  const { frontmatter, html: content } = data.markdownRemark;
  const { hero, openGraph } = frontmatter.heroImage.image.childImageSharp;
  const mimeType = getMimeTypeFromExtension(hero.src);

  return (
    <>
      <Helmet>
        <title>{frontmatter.title}</title>
        <meta name="description" content={frontmatter.description} />
        <meta
          property="og:image"
          content={`http://${location.hostname}${location.port !== '80' &&
            `:${location.port}`}${openGraph.src}`}
        />
        <meta
          property="og:image:secure_url"
          content={`https://${location.hostname}${location.port !== '443' &&
            `:${location.port}`}${openGraph.src}`}
        />
        {mimeType && <meta property="og:image:type" content={mimeType} />}
        <meta property="og:image:width" content={`${openGraph.width}`} />
        <meta property="og:image:height" content={`${openGraph.height}`} />
        {frontmatter.heroImage.alt && (
          <meta property="og:image:alt" content={frontmatter.heroImage.alt} />
        )}
      </Helmet>
      <HeroPageTemplate
        title={frontmatter.title}
        location={location.href}
        schema={{
          '@type': frontmatter.type,
          name: frontmatter.title,
          description: frontmatter.description,
          url: location.href,
          primaryImageOfPage: {
            '@type': 'ImageObject',
            contentUrl: hero.src,
            width: hero.width,
            height: hero.height,
            ...(frontmatter.heroImage.alt && {
              description: frontmatter.heroImage.alt,
            }),
          },
          headline: frontmatter.heading,
          alternativeHeadline: frontmatter.subheading,
        }}
        contentComponent={HTMLContent}
        image={hero}
        focalPoint={frontmatter.heroImage.focalPoint}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        content={content}
      />
    </>
  );
}

export const heroPageQuery = graphql`
  query HeroPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        type
        title
        description
        heroImage {
          alt
          focalPoint {
            x
            y
          }
          image {
            childImageSharp {
              hero: fluid(maxWidth: 2048) {
                ...GatsbyImageSharpFluid_noBase64
                width: presentationWidth
                height: presentationHeight
              }
              openGraph: resize(width: 1200, height: 1200) {
                src
                width
                height
              }
            }
          }
        }
        heading
        subheading
      }
    }
  }
`;
