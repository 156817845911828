import React from 'react';
import { FluidObject } from 'gatsby-image';

interface Image extends FluidObject {
  width: number;
  height: number;
}

interface Props {
  heading: string;
  subheading?: string;
  footer?: string;
  image: string | Image;
  focalPoint: {
    x: number;
    y: number;
  };
  title?: string;
}

export default function Hero({
  heading,
  subheading,
  footer,
  image,
  focalPoint,
}: Props) {
  return (
    <div
      className="hero is-dark is-halfheight"
      style={{
        ...(image && {
          backgroundImage: `url(${
            typeof image === 'string' ? image : image.src
          })`,
        }),
        backgroundPosition: `${focalPoint.x}% ${focalPoint.y}%`,
        backgroundSize: 'cover',
      }}
    >
      <div className="hero-body">
        <div
          style={{
            display: 'flex',
            height: '150px',
            lineHeight: 1,
            justifyContent: 'space-around',
            flexDirection: 'column',
          }}
        >
          <h1
            className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
            style={{
              boxShadow:
                'rgb(163, 17, 23) 0.5rem 0px 0px, rgb(163, 17, 23) -0.5rem 0px 0px',
              backgroundColor: 'rgb(163, 17, 23)',
              color: 'white',
              lineHeight: 1,
              padding: '0.25em',
            }}
          >
            {heading}
          </h1>
          {subheading && (
            <h3
              className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
              style={{
                boxShadow:
                  'rgb(163, 17, 23) 0.5rem 0px 0px, rgb(163, 17, 23) -0.5rem 0px 0px',
                backgroundColor: 'rgb(163, 17, 23)',
                color: 'white',
                lineHeight: 1,
                padding: '0.25em',
              }}
            >
              {subheading}
            </h3>
          )}
        </div>
      </div>
      {footer && (
        <div className="hero-foot">
          <div className="container">{footer}</div>
        </div>
      )}
    </div>
  );
}
